import { render, staticRenderFns } from "./esports.vue?vue&type=template&id=79fbd7ed"
import script from "./esports.vue?vue&type=script&lang=js"
export * from "./esports.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutCookieConsent: require('/builds/athlyzer-devs/athlyzer-website/components/layout/cookie-consent.vue').default,LayoutHeader: require('/builds/athlyzer-devs/athlyzer-website/components/layout/header.vue').default,LayoutFooter: require('/builds/athlyzer-devs/athlyzer-website/components/layout/footer.vue').default})
