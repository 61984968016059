export default ({ app }, inject) => {
  // Inject $hello(msg) in Vue, context and store.
  inject('dynamicObjectPosition', (position) => {
    const tailwindObjectPositions = {
      top: 'object-top',
      center: 'object-center',
      bottom: 'object-bottom',
      left: 'object-left',
      right: 'object-right',
    }
    const result = tailwindObjectPositions[position] || ''
    return result
  })
}
