
export default {
  name: 'LayoutFooter',
  data() {
    return {
      solutionsSports: [],
      solutionsRoles: [],
    }
  },
  async fetch() {
    try {
      const blogPosts = await this.$prismic.api.query(
        this.$prismic.predicates.at('document.type', 'blog_post'),
        {
          lang: this.$i18n.localeProperties.prismic,
          orderings: '[document.first_publication_date desc]',
          page: 1,
          pageSize: 2,
        },
      )

      this.firstTwoBlogPosts = blogPosts.results
    } catch (error) {
      this.firstTwoBlogPosts = []
    }

    try {
      const menu = (
        await this.$prismic.api.getSingle('menu', {
          lang: this.$i18n.localeProperties.prismic,
        })
      ).data

      this.solutionsRoles = menu.solutions_roles
      this.solutionsSports = menu.solutions_sports
    } catch (error) {
      this.solutionsRoles = []
      this.solutionsSports = []
    }
  },
  watch: {
    '$i18n.locale': '$fetch',
  },
  fetchKey: 'menu',
}
