export default class Pakete {
  toJSON() {
    return { ...this } // here I make a POJO's copy of the class instance
  }

  paketNamen = {
    free: ['Viewer'],
    rookie: ['Rookie'],
    professional: [
      'Professional',
      'Partner',
      'Allstars',
      'Education',
      '10 Days Free',
    ],
    expert: ['Expert'],
    level1: ['Esport Lvl 1'],
    level2: ['Esport Lvl 2'],
    level3: ['Esport Lvl 3'],
  }

  pakete = {
    sharedlib: {
      read: [
        ...this.paketNamen.rookie,
        ...this.paketNamen.professional,
        ...this.paketNamen.level2,
        ...this.paketNamen.level3,
      ],
      full: [...this.paketNamen.professional, ...this.paketNamen.level3],
    },
    file: {
      import: {
        full: [
          ...this.paketNamen.rookie,
          ...this.paketNamen.professional,
          ...this.paketNamen.level2,
          ...this.paketNamen.level3,
        ],
      },
      export: {
        full: [
          ...this.paketNamen.rookie,
          ...this.paketNamen.professional,
          ...this.paketNamen.level2,
          ...this.paketNamen.level3,
        ],
      },
    },
    analysis: {
      livetagging: {
        full: [
          ...this.paketNamen.free,
          ...this.paketNamen.rookie,
          ...this.paketNamen.professional,
          ...this.paketNamen.level3,
        ],
      },
      capture: {
        full: [
          ...this.paketNamen.free,
          ...this.paketNamen.rookie,
          ...this.paketNamen.professional,
        ],
      },
      rtsp: {
        full: [
          ...this.paketNamen.free,
          ...this.paketNamen.rookie,
          ...this.paketNamen.professional,
        ],
      },
      draw: {
        live: [
          ...this.paketNamen.free,
          ...this.paketNamen.rookie,
          ...this.paketNamen.professional,
          ...this.paketNamen.level1,
          ...this.paketNamen.level2,
          ...this.paketNamen.level3,
        ],
        save: [
          ...this.paketNamen.professional,
          ...this.paketNamen.level2,
          ...this.paketNamen.level3,
        ],
      },
      clip: {
        export: {
          optionalwatermark: [
            ...this.paketNamen.rookie,
            ...this.paketNamen.professional,
            ...this.paketNamen.level3,
          ], // Noch Umsetzen
          full: [...this.paketNamen.professional, ...this.paketNamen.level3],
        },
      },
    },
    playlist: {
      manual: [
        ...this.paketNamen.free,
        ...this.paketNamen.rookie,
        ...this.paketNamen.professional,
        ...this.paketNamen.level1,
        ...this.paketNamen.level2,
        ...this.paketNamen.level3,
      ],
      automated: [
        ...this.paketNamen.rookie,
        ...this.paketNamen.professional,
        ...this.paketNamen.level2,
        ...this.paketNamen.level3,
      ],
    },
    collection: {
      dependend: [
        ...this.paketNamen.rookie,
        ...this.paketNamen.professional,
        ...this.paketNamen.level2,
        ...this.paketNamen.level3,
      ],
      full: [...this.paketNamen.professional],
    },
    scheme: {
      counter: {
        full: [...this.paketNamen.professional],
      },
      timer: {
        full: [...this.paketNamen.professional],
      },
      hotkeys: {
        full: [
          ...this.paketNamen.professional,
          ...this.paketNamen.level2,
          ...this.paketNamen.level3,
        ],
      },
    },
    board: {
      save: [
        ...this.paketNamen.rookie,
        ...this.paketNamen.professional,
        ...this.paketNamen.level2,
        ...this.paketNamen.level3,
      ],
      export: [
        ...this.paketNamen.rookie,
        ...this.paketNamen.professional,
        ...this.paketNamen.level2,
        ...this.paketNamen.level3,
      ],
    },
  }
}
