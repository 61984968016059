
import { bootstrap } from 'vue-gtag'

export default {
  name: 'CookieConsentComponent',
  data() {
    return {
      showBrevoLiveChat: false,
      showMoreDetailsBanner: false,
    }
  },
  head() {
    if (this.showBrevoLiveChat) {
      window.pipedriveLeadboosterConfig = {
        base: 'leadbooster-chat.pipedrive.com',
        companyId: 12838264,
        playbookUuid: '5b5138a7-d62b-453f-b381-05a0cf20bfa6',
        version: 2,
      }
      const w = window
      if (w.LeadBooster) {
        console.warn('LeadBooster already exists')
      } else {
        w.LeadBooster = {
          q: [],
          on(n, h) {
            this.q.push({ t: 'o', n, h })
          },
          trigger(n) {
            this.q.push({ t: 't', n })
          },
        }
      }

      return {
        script: [
          {
            hid: 'webchat',
            id: 'pd-script-loader',
            async: true,
            src: 'https://leadbooster-chat.pipedrive.com/assets/loader.js',
          },
        ],
      }
    }
  },
  mounted() {
    const cookieConsentBannerElement = document.querySelector(
      'cookie-consent-banner',
    )

    if (cookieConsentBannerElement) {
      cookieConsentBannerElement.availableCategories = [
        {
          description: this.$t(
            'Ermöglicht Dir die Navigation und die Nutzung der Grundfunktionen sowie das Speichern von Einstellungen.',
          ),
          key: 'technically_required',
          label: this.$t('Technisch notwendige Cookies'),
          isMandatory: true,
        },
        {
          description: this.$t(
            'Ermöglicht es uns festzustellen, wie Besucher mit unserem Produkt interagieren, um die Benutzererfahrung zu verbessern.',
          ),
          key: 'analytics',
          label: 'Analytics',
        },
        {
          description: this.$t(
            'Ermöglicht es uns relevante Inhalte und interessenbezogene Werbung anzubieten und auszuwerten.',
          ),
          key: 'marketing',
          label: 'Marketing',
        },
      ]

      this.$gtag.query('consent', 'default', {
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        ad_storage: 'denied',
        analytics_storage: 'denied',
        wait_for_update: 500,
      })

      cookieConsentBannerElement.addEventListener(
        'cookie_consent_preferences_restored',
        (event) => {
          console.log('cookie_consent_preferences_restored', event.detail)
          if (
            event.detail.acceptedCategories &&
            event.detail.acceptedCategories.includes('analytics')
          ) {
            this.$mixpanel.opt_in_tracking()

            this.showBrevoLiveChat = true
          } else {
            this.$mixpanel.opt_out_tracking()
          }

          if (
            event.detail.acceptedCategories &&
            event.detail.acceptedCategories.includes('marketing')
          ) {
            bootstrap().then((gtag) => {
              this.$gtag.optIn()
              this.$gtag.query('consent', 'update', {
                ad_user_data: 'granted',
                ad_personalization: 'granted',
                ad_storage: 'granted',
                analytics_storage: 'granted',
              })
            })
            this.$fb.init()
            this.$fb.enable()
          } else {
            this.$gtag.optOut()
            this.$fb.disable()

            this.$gtag.query('consent', 'update', {
              ad_user_data: 'denied',
              ad_personalization: 'denied',
              ad_storage: 'denied',
              analytics_storage: 'denied',
              wait_for_update: 500,
            })
          }
        },
      )

      cookieConsentBannerElement.addEventListener(
        'cookie_consent_preferences_updated',
        (event) => {
          console.log('cookie_consent_preferences_updated', event.detail)
          if (
            event.detail.acceptedCategories &&
            event.detail.acceptedCategories.includes('analytics')
          ) {
            this.$mixpanel.opt_in_tracking()
          } else {
            this.$mixpanel.opt_out_tracking()
          }

          if (
            event.detail.acceptedCategories &&
            event.detail.acceptedCategories.includes('marketing')
          ) {
            bootstrap().then((gtag) => {
              this.$gtag.optIn()
            })

            this.showBrevoLiveChat = true
          } else {
            this.$gtag.optOut()

            this.$gtag.query('consent', 'update', {
              ad_user_data: 'denied',
              ad_personalization: 'denied',
              ad_storage: 'denied',
              analytics_storage: 'denied',
              wait_for_update: 500,
            })

            this.showBrevoLiveChat = false
          }
        },
      )
    }
  },
  methods: {
    dispatchCookieBannerShowEvent() {
      document.dispatchEvent(new CustomEvent('cookie_consent_show'))
    },
    dispatchCookieBannerShowMoreEvent() {
      document.dispatchEvent(new CustomEvent('cookie_consent_details_show'))
      this.showMoreDetailsBanner = true
    },
  },
}
