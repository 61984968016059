import { extend } from 'vee-validate'
import { required, email, min, confirmed } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Required',
})

extend('email', email)
extend('min', min)
extend('confirmed', confirmed)
