
export default {
  name: 'DefaultLayout',
  async mounted() {
    this.$store.dispatch('auth/retrieveCustomer')
    this.$store.dispatch('auth/retrieveCustomerAccess')

    if (this.$superlogin.getSession()) {
      this.$store.commit(
        'pricing/setPaket',
        this.$superlogin.getSession()?.paket,
      )
      this.$store.commit(
        'pricing/setPaketName',
        this.$superlogin.getSession()?.paketName,
      )
    }
    const token = this.$route.query.t
    const password = this.$route.query.p

    if (this.$superlogin.authenticated() && token && password) {
      this.$store.commit('auth/logout')
    }

    if (!this.$superlogin.authenticated()) {
      if (token && password) {
        const response = await this.$superlogin.loginExistingSession(
          token,
          password,
        )

        localStorage.setItem('loginEmail', response.data.email)
        localStorage.setItem('authToken', token)
        localStorage.setItem('authPassword', password)

        this.$store.dispatch('auth/retrieveCustomer')
        this.$store.dispatch('auth/retrieveCustomerAccess')

        this.$router.push({
          path: this.$route.path,
          query: {},
        })
      }
    }
  },
}
