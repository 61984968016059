import Superlogin from '~/superlogin/src/index'

export default ({ $config: { hostaddress } }, inject) => {
  Superlogin.configure({
    serverUrl: hostaddress,
    baseUrl: '/auth',
    socialUrl: hostaddress + '/auth',
    noDefaultEndpoint: false,
    storage: 'local',
    checkExpired: false,
    timeout: 0,
    refreshThreshold: 0.5,
  })

  inject('superlogin', Superlogin)
}
