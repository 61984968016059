import Pakete from '~/utils/pakete'

export const state = () => ({
  prices: {
    '1-month': {
      monthly: {
        storage: 10,
      },
    },
    '1-year': {
      yearly: {
        rookie: 38,
        professional: 50,
      },
      monthly: {
        rookie: 41,
        professional: 55,
      },
    },
    '2-year': {
      yearly: {
        rookie: 28,
        professional: 35,
      },
      monthly: {
        rookie: 30,
        professional: 38.5,
      },
    },
  },
  newPrices: {
    '1-month': {
      monthly: {
        storage: 10,
      },
    },
    '1-year': {
      yearly: {
        rookie: 38,
        professional: 70,
        expert: 120,
      },
      monthly: {
        rookie: 42,
        professional: 77,
        expert: 130,
      },
    },
    '2-year': {
      yearly: {
        rookie: 33,
        professional: 60,
        expert: 100,
      },
      monthly: {
        rookie: 36,
        professional: 66,
        expert: 110,
      },
    },
  },
  pakete: new Pakete().toJSON(),
  paketName: 'Free',
  paket: 1,
  interval: '2-year',
  paymentInterval: 'yearly',
})

export const getters = {
  getLicenseLevel(state) {
    let license = ''
    if (state.pakete) {
      const paketNamen = state.pakete.paketNamen

      if (state.paketName === '10 Days Free') {
        license = 'Free'
        return license
      }

      if (paketNamen.free.includes(state.paketName)) {
        license = 'Free'
      } else if (paketNamen.rookie.includes(state.paketName)) {
        license = 'Rookie'
      } else if (paketNamen.professional.includes(state.paketName)) {
        license = 'Professional'
      } else if (paketNamen.expert.includes(state.paketName)) {
        license = 'Expert'
      } else if (paketNamen.level1.includes(state.paketName)) {
        license = 'Level 1'
      } else if (paketNamen.level2.includes(state.paketName)) {
        license = 'Level 2'
      } else if (paketNamen.level3.includes(state.paketName)) {
        license = 'Level 3'
      } else {
        license = 'Free'
      }
    }

    return license
  },
}

export const mutations = {
  setPaket(state, value) {
    state.paket = value
  },
  setPaketName(state, value) {
    state.paketName = value
  },
  setInterval(state, value) {
    state.interval = value
  },
  setPaymentInterval(state, value) {
    state.paymentInterval = value
  },
}
