
import GermanIcon from '../basics/icons/german-icon.vue'
import EuropeanUnionIcon from '../basics/icons/european-union-icon.vue'

export default {
  name: 'LocaleSwitcherComponent',
  components: {
    GermanIcon,
    EuropeanUnionIcon,
  },
  data() {
    return {
      menuToggle: false,
      currentIcon: GermanIcon,
      languages: {
        en: { icon: EuropeanUnionIcon },
        de: { icon: GermanIcon },
      },
    }
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales
    },
  },
  watch: {
    '$i18n.locale'(newVal, oldVal) {
      this.currentIcon = this.languages[newVal].icon
    },
  },
  mounted() {
    this.currentIcon = this.languages[this.$i18n.locale].icon
  },
  methods: {
    closeMenuAndSwitch(code) {
      this.menuToggle = false

      this.$i18n.setLocale(code)
    },
    closeMenu() {
      this.menuToggle = false
    },
  },
}
