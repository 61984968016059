
import ChevronDownIcon from '../basics/icons/chevron-down.vue'
import FieldHockeyIcon from '../basics/icons/field-hockey-icon.vue'
import IceHockeyIcon from '../basics/icons/ice-hockey-icon.vue'
import SoccerIcon from '../basics/icons/soccer-icon.vue'
import VolleyballIcon from '../basics/icons/volleyball-icon.vue'
import BasketballIcon from '../basics/icons/basketball-icon.vue'
import OfficialsIcon from '../basics/icons/officials-icon.vue'
import StudentIcon from '../basics/icons/student-icon.vue'
import TeacherIcon from '../basics/icons/teacher-icon.vue'

export default {
  name: 'HeaderFlyoutTwoColumns',
  components: {
    FieldHockeyIcon,
    IceHockeyIcon,
    SoccerIcon,
    ChevronDownIcon,
  },
  props: {
    mobile: Boolean,
    colorStyle: String,
    firstTwoBlogPosts: { type: Array, default: () => [] },
    solutionsSports: { type: Array, default: () => [] },
    solutionsRoles: { type: Array, default: () => [] },
  },
  data() {
    return {
      open: false,
      icons: {
        Feldhockey: FieldHockeyIcon,
        Fussball: SoccerIcon,
        Eishockey: IceHockeyIcon,
        Basketball: BasketballIcon,
        Volleyball: VolleyballIcon,
        Offizielle: OfficialsIcon,
        Spieler: StudentIcon,
        Trainer: TeacherIcon,
      },
    }
  },
  methods: {
    clickawayFlyOut(e) {
      if (
        this.open &&
        e.target.id !== 'mobile-menu-button-icon' &&
        e.target.id !== 'mobile-menu-button'
      ) {
        this.open = false
      }
    },
  },
}
