import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default ({ app }) => {
  Vue.use(
    VueGtag,
    {
      config: {
        id: 'G-1V50N7R4LR',
        params: {
          anonymize_ip: true,
        },
      },
      appName: 'Website',
      pageTrackerTemplate: (to, from) => to.fullPath,
      bootstrap: false,
      enabled: false,
    },
    app.router,
  )
}
