
export default {
  name: 'EsportsLayout',
  mounted() {
    this.$store.dispatch('auth/retrieveCustomer')
    this.$store.dispatch('auth/retrieveCustomerAccess')

    if (this.$superlogin.getSession()) {
      this.$store.commit(
        'pricing/setPaket',
        this.$superlogin.getSession()?.paket,
      )
      this.$store.commit(
        'pricing/setPaketName',
        this.$superlogin.getSession()?.paketName,
      )
    }
  },
}
