import mixpanel from 'mixpanel-browser'

export default ({ $config: { isDevMode } }, inject) => {
  mixpanel.init('afcb4dd1916a941c97a34fe662ca478a', {
    debug: isDevMode,
    api_host: 'https://api-eu.mixpanel.com',
    opt_out_tracking_by_default: true,
  })

  inject('mixpanel', mixpanel)
}
