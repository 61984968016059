export const state = () => ({
  authenticated: false,
  customer: null,
  session: null,
})

export const mutations = {
  checkAuthenticated(state) {
    state.authenticated = this.$superlogin.authenticated()
  },
  setCustomer(state, customer) {
    state.customer = customer
  },
  setSession(state) {
    const session = this.$superlogin.getSession()

    if (session) {
      state.session = session
    }

    this.$mixpanel.identify(session.user_id)
  },
  logout() {
    if (this.$superlogin.getSession()) {
      this.$superlogin.logout()
      this.$superlogin.deleteSession()
    }
    localStorage.removeItem('loginEmail')
    localStorage.removeItem('authPassword')
    localStorage.removeItem('authToken')
    this.commit('auth/setCustomer', null)
    this.commit('pricing/setPaket', 1)
    this.commit('pricing/setPaketName', 'Free')
    this.commit('auth/checkAuthenticated')
  },
}

export const actions = {
  async retrieveCustomer({ commit, state }) {
    commit('checkAuthenticated')
    if (state.authenticated) {
      const { data } = await this.$superlogin
        .getHttp()
        .get('/user/stripe/session/find/customer')

      commit('setCustomer', data)
    }
  },
  async retrieveCustomerAccess({ commit, state }) {
    commit('checkAuthenticated')

    if (state.authenticated) {
      const { data } = await this.$superlogin.getHttp().get('/user/access')

      this.commit('pricing/setPaket', data.paket)
      this.commit('pricing/setPaketName', data.paketName)
    }
  },
}
